import PropTypes from "prop-types"
import React from "react"

const Header = () => (
  <header className="header">
    <div className="header-wrapper">
      <img
        src={require("../images/logo@2x.png")}
        id="header-logo"
        alt="Reviewvio Logo"
      />
      <div className="rating-wrapper">
        <p>Secure checkout</p>
        {/* <img
          src={require("../images/rating-new.png")}
          id="header-rating"
          alt="A+ Rating"
        /> */}
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
